import { useState, useEffect } from "react";
import Home from "./Home";
import Loading from "./Loading";

 const Landing = ({showCircle}) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3500);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className=" bg-[#1a1a1a]">
            <Loading /> 
            <Home showCircle={showCircle}/>
        </div>
    )
}

export default Landing;