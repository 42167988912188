import React, { useState, useEffect, useRef } from "react";
import Contact from "./Contact";
import { Link } from "react-router-dom";
import { MdKeyboardBackspace } from "react-icons/md";
import {
  motion
} from "framer-motion"
import { useLocation } from "react-router-dom";

const Work = ({showCircle}) => {
  const projects = [
    {
      name: "Stock Broker",
      sourceCode: "https://github.com/aakashahpl/stock-broker",
      demoLink: "https://stock-broker-tau.vercel.app/",
      imageSrc: "/portfolio/stockBroker.png",
      about: {
        line1: "Web application that allows users to access stock data, execute buy/sell transactions, and receive real-time stock price updates and business news.",
        line2: ""
      }
    },
    {
      name: "Blog Website",
      sourceCode: "https://github.com/aakashahpl/ang-blogWebsite",
      demoLink: "https://blog-app-blue-xi.vercel.app/",
      imageSrc: "/portfolio/blog-website.png",
      about: {
        line1: "Developed server side of the application focusing on user management, blogs and comment handling ",
        line2: " - Utilized PassportJs, JWT for secure user management, and Multer for handling file uploads (multipart/form-data)."
      }
    },
    {
      name: "India Tourism",
      sourceCode: "https://github.com/aakashahpl/indiaTourism",
      demoLink: "",
      imageSrc: "/portfolio/tourism.jpg",
      about: {
        line1: "A tourism webiste which creates personalized travel itineraries based on user preferences.",
        line2: ""
      }
    },
    {
      name: "Skill Forge",
      sourceCode: "https://github.com/aakashahpl/Skill-forge",
      demoLink: "",
      imageSrc: "/portfolio/skillForge.jpg",
      about: {
        line1: "Educational web app that allows users to generate personalized programming courses using Google's Gemini API.",
        line2: " - Integrated course creation features based on custom prompts for any programming concept or language."
      }
    },
  ];

  const [scrollPosition, setScrollPosition] = useState(0);
  const [imageIndex, setImageIndex] = useState(0);
  const [distortionLevel, setDistortionLevel] = useState(0);
  const lastScrollY = useRef(0);
  const animationFrameId = useRef(null);
  const resetTimeoutId = useRef(null);
  const isScrolling = useRef(false);


  const location = useLocation();
  useEffect(() => {
    // Reset showCircle to false when the route changes
    showCircle(false);
  }, [location.pathname]);  

  // Handle scroll event and update distortion level
  useEffect(() => {
    let lastScrollTime = 0;
    const updateDistortion = (timestamp) => {
      const currentScrollY = window.scrollY;
      const scrollDiff = Math.abs(currentScrollY - lastScrollY.current);

      if (scrollDiff > 0) {
        const timeDiff = timestamp - lastScrollTime;
        const scrollSpeed = scrollDiff / timeDiff;

        const newDistortion = Math.min(scrollSpeed * 50, 1);
        setDistortionLevel(newDistortion);
        lastScrollTime = timestamp;
        isScrolling.current = true;

        if (resetTimeoutId.current) {
          clearTimeout(resetTimeoutId.current);
        }
      } else if (isScrolling.current) {
        resetTimeoutId.current = setTimeout(() => {
          setDistortionLevel(0);
          isScrolling.current = false;
        }, 210);
      }

      lastScrollY.current = currentScrollY;
      animationFrameId.current = requestAnimationFrame(updateDistortion);
    };

    animationFrameId.current = requestAnimationFrame(updateDistortion);

    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
      if (resetTimeoutId.current) {
        clearTimeout(resetTimeoutId.current);
      }
    };
  }, []);

  useEffect(() => {
    if (scrollPosition < 1000) {
      setImageIndex(0);
    } else if (scrollPosition >= 1300 && scrollPosition < 1950) {
      setImageIndex(1);
    } else if (scrollPosition >= 1950 && scrollPosition < 3000) {
      setImageIndex(2);
    } else if (scrollPosition > 3000) {
      setImageIndex(3);
    }
  }, [scrollPosition]);

  const getImageStyle = () => {
    return {
      filter: `blur(${distortionLevel * 2}px) brightness(${1 - distortionLevel * 0.2})`,
      transition: 'filter 0.3s ease-out', // Smooth transition for blur and brightness
      willChange: 'filter',
    };
  };

  return (
    <div className="bg-[#1a1a1a] min-h-screen w-full justify-center items-center text-neutral-200 font-mulish">
      <div className="w-full min-h-[150px] border-b-2 border-neutral-600 flex items-center px-10">
        <Link to="/">
          <MdKeyboardBackspace size={70} />
        </Link></div>
      <motion.div initial={{ x: -300, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} transition={{ duration: 2, type: 'spring', delay: 0.5 }} viewport={{once:true}}
        className="w-11/12 min-h-[300px] text-[10rem] uppercase font-coign justify-start px-20 align-middle items-center tracking-wide"
      >
        Recent Work
      </motion.div>
      <div className="flex flex-row min-h-screen sticky -top-[2800px]">
        <div className="w-2/5 items-end justify-center flex flex-col">
          {projects.map((item, index) => (
            <div key={index} className="h-screen flex items-end justify-center flex-col w-full">
              <div className="w-4/5  flex flex-col items-start gap-6 -mr-32">
                <h1 className="text-[5rem] w-full flex justify-start z-30 uppercase">{item.name}</h1>
                <h2 className="text-lg flex justify-end w-10/12 z-30">{item.about.line1}</h2>
                {/* <h2 className="text-lg flex justify-end w-10/12 z-30">{scrollPosition}</h2> */}
              </div>
            </div>
          ))}
        </div>
        <div className="w-3/5 z-10 relative">
          <div className="flex items-center h-[100vh] sticky top-0">
            <img
              onMouseEnter={() => showCircle(2)} 
              onMouseLeave={() => showCircle(false)}  
              src={projects[imageIndex].imageSrc}
              className="w-10/12 rounded-md"
              style={getImageStyle()}
              alt={projects[imageIndex].name}
            />
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
};

export default Work;
