import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const About = () => {
    useEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#svg-container", // Element to watch
                start: "top 80%", // Animation starts when top of SVG is 80% into the viewport
                end: "top 50%", // Animation ends when top of SVG is 50% into the viewport
                toggleActions: "play none none none", // Play animation only once
            },
        });

        tl.fromTo(
            "#line1",
            { strokeDasharray: 559, strokeDashoffset: 559 },
            { strokeDashoffset: 0, duration: 1 }
        )
            .fromTo(
                "#line2",
                { strokeDasharray: 445, strokeDashoffset: 445 },
                { strokeDashoffset: 0, duration: 1 },
                "-=0.5"
            )
            .fromTo(
                "#line3",
                { strokeDasharray: 559, strokeDashoffset: 559 },
                { strokeDashoffset: 0, duration: 1 },
                "-=0.5"
            )
            .fromTo(
                "#line4",
                { strokeDasharray: 445, strokeDashoffset: 445 },
                { strokeDashoffset: 0, duration: 1 },
                "-=0.5"
            );
    }, []);

    return (
        <div
            name="about"
            className="w-full h-screen bg-[#1a1a1a] text-white font-mulish flex"
        >


            <div className="max-w-screen-xl p-4 mx-auto flex flex-col justify-center w-full h-full gap-10">
                <motion.div
                    className="mb-20 text-center"
                    initial={{ x: -100, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 1.3, type: "spring", delay: 0.3 }}
                    viewport={{ once: true }}
                >
                    <p className="text-4xl inline font-mulish">ABOUT</p>
                </motion.div>
                <div className="flex gap-10">
                    <div className="hidden lg:flex items-center relative h-[470px] overflow-hidden">
                        <div id="svg-container" className="flex items-center justify-center w-full absolute left-0">
                            <svg
                                width="446"
                                height="559"
                                viewBox="0 0 446 559"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <line id="line1" x1="0.5" y1="1" x2="0.499976" y2="559" stroke="white" stroke-width="2" />
                                <line id="line2" y1="0.5" x2="445" y2="0.5" stroke="white" stroke-width="2" />
                                <line id="line3" x1="445.5" y1="1" x2="445.5" y2="559" stroke="white" stroke-width="2" />
                                <line id="line4" y1="558.5" x2="445" y2="558.5" stroke="white" stroke-width="2" />
                            </svg>
                        </div>
                        <img
                            src="https://i.pinimg.com/736x/df/df/3d/dfdf3d42b36b383f5277d2734dd820d8.jpg"
                            alt="dogoos"
                            width={2000}
                        />
                    </div>
                    <div>
                        <motion.p
                            className="text-md lg:text-xl mt-20"
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ delay: 0.5, duration: 1.2 }}
                            viewport={{ once: true, amount: 0.5 }}
                        >
                            "I'm a full-stack developer with expertise in Express, Node.js, React, Tailwind CSS, and Next.js. I specialize in building both the backend and frontend of web applications. Using Express and Node.js, I create robust APIs and backend functionality, while leveraging React, Next.js, and Tailwind CSS to develop responsive, user-friendly interfaces. My focus is on delivering scalable, high-performance applications by seamlessly integrating these technologies throughout the entire development lifecycle."
                        </motion.p>
                        <br />
                        <motion.p
                            className="text-md lg:text-xl"
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ delay: 0.5, duration: 1.2 }}
                            viewport={{ once: true, amount: 0.5 }}
                        >
                            I am familiar with tools like Git and GitHub and can work efficiently in a team. I have collaborated in various projects in both frontend and backend.
                        </motion.p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
